export default {
    nl: {
        categories: {
            tab_title: 'Categorieën',

            create_category: 'Categorie toevoegen',
            create_sub_category: 'Subcategorie toevoegen',
            edit_category: 'Categorie aanpassen',
            header: 'Hoofdcategorie kopje',

            save: 'Categorie opslaan',
            delete: 'Categorie verwijderen',

            save_data: 'Gegevens opslaan',

            name: 'Naam',
            meta: 'Meta',
            meta_title: 'Meta titel',
            meta_description: 'Meta beschrijving',

            choice_help_question: 'Keuzehulp startvraag',
            choice_help_show_menu: 'Toon keuzehulp in menu',
            choice_help_show_page: 'Toon keuzehulp op pagina',
            choice_help_description: 'Keuzehulp categorie beschrijving',
            choice_help: 'Keuzehulp',

            categories: 'Categorieën',
            category: 'Categorie',
            new: 'Nieuwe categorie toevoegen',
            new_sub_category: 'Nieuwe subcategorie toevoegen',
            options: 'Categorie opties',
            option: 'Categorie',
            sort_method: 'Standaard sorteer methode',
            add_category: 'Antwoord toevoegen',
            filters: 'Filters',
            products: 'Producten',

            is_inspiration: 'Inspiratie',
            is_custom: 'Maatwerk & Showroom',
            is_solution: 'Is oplossing',
            is_hidden: 'Niet zichtbaar in navigatie',
            can_filter_accessoires: 'Accessoires filterbaar',

            save_success: 'Categorie opgeslagen',
            update_success: 'Categorie aangepast',

            seo_content: 'SEO Content',
            settings: 'Instellingen',

            delete_modal: {
                title: 'Categorie verwijderen',
                content: 'Wil je deze categorie echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },

            category_dividers: 'Menu kopjes',

            dividers: {
                create: 'Nieuw kopje toevoegen',
                name: 'Naam',
                save: 'Opslaan',
                add: 'Kopje aanmaken',
                save_success: 'Succesvol opgeslagen',
                update_success: 'Succesvol aangepast',
            }
        },
    },
}
