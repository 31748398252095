<template>
    <div class="product-detail">
        <div class="page-header page-header--push-top">
            <h1 class="page-title">{{ $t('workers.edit_title') }}</h1>
        </div>

        <div class="half-size">
            <div class="field">
                <label class="label">{{ $t('workers.first_name') }}</label>
                <div class="field-control">
                    <input type="text" v-model="item.first_name" />
                </div>
            </div>
            <div class="field">
                <label class="label">{{ $t('workers.last_name') }}</label>
                <div class="field-control">
                    <input type="text" v-model="item.last_name" />
                </div>
            </div>
            <div class="field">
                <label class="label">{{ $t('workers.function') }}</label>
                <div class="field-control">
                    <input type="text" v-model="item.function" />
                </div>
            </div>
            <div class="field field--image">
                <label class="label">{{ $t('workers.image') }}</label>
                <div class="field-control" v-if="item.image == null || item.image == ''">
                    <label for="image-input" class="btn btn--blue">
                        {{ $t('images.add_image') }}
                    </label>

                    <input id="image-input"
                           name="images"
                           type="file"
                           accept="image/*"
                           @change="imageChange">
                </div>
                <div class="field-control" v-else>
                    <div class="image-container">
                        <img class="image" :src="item.image" alt="">
                        <div class="icon" @click="item.image = null">
                            <img src="/svgs/close-icon.svg" alt="close-icon">
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <a href="#" class="btn btn--orange" @click.prevent="save">
            {{ $t('general.save') }}
        </a>
        <a href="#" class="btn btn--link" v-if="item.id" @click.prevent="deleteModal = true">
            {{ $t('general.delete') }}
        </a>
        <BaseModal v-if="deleteModal"
                   :is-confirmation="true"
                   :title="$t('workers.delete_modal_title')"
                   :content="$t('workers.delete_modal_content')"
                   @close="deleteModal = false"
                   @confirm="deleteItem()" />
    </div>
</template>
<script>
    import WorkersService from "../../services/workers";
    import BaseModal from '../../components/BaseModal';

    export default {
        name: 'workers-edit',
        components: {
            BaseModal
        },
        data() {
            return {
                deleteModal: false,
                item: {
                    image: null,
                },
            };
        },
        computed: {
            id() {
                return this.$route.params.id;
            }
        },
        async beforeMount() {
            if (this.id != null) {
                this.item = (await WorkersService.show(this.id)).data.data;
            }
        },
        methods: {
            imageChange(e) {
                const files = e.target.files || e.dataTransfer.files;
                const reader = new FileReader();

                if (!files.length) {
                    this.item.image = null;
                    return;
                }
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    this.item.image = e.target.result;
                };
            },

            async deleteItem() {
                await WorkersService.destroy(this.item.id);
                this.$router.push({ path: '/workers' });
            },

            save() {
                let promise;
                if (this.id != null) {
                    promise = WorkersService.update(this.id, this.item);
                } else {
                    promise = WorkersService.store(this.item);
                }

                promise.then(_ => {
                    this.$flashMessage(this.$t('general.update_success'), 3500, 'success');
                    this.$router.push({ path: '/workers' });
                }).catch(({ response }) => {
                    if (response.status == 422) {
                        Object.entries(response.data.errors).map(error =>
                            error[1].map(err => this.$flashMessage(err, 3500, 'error'))
                        )
                    } else {
                        this.$flashMessage(this.$t('general.update_failed'), 3500, 'error');
                    }
                });
            },
        },
    }
</script>
