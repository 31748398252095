<template>
    <div :class="{'invalid': isInvalid}"
         class="sub-panel">
        <div class="sub-panel-heading">
            <h3 class="sub-panel-title">{{ title }}</h3>
            <div class="ordering">
                <img v-if="this.localAnswer.order > 1" src="/svgs/up-arrow.svg" class="order order-up" @click="moveOrderUp" />
                <img v-if="!this.isLastAnswer" src="/svgs/down-arrow.svg" class="order order-down" @click="moveOrderDown" />
            </div>
            <options-menu>
                <a @click.prevent="deleteAnswer"
                   href="#">Verwijderen</a>
            </options-menu>
        </div>

        <div class="field">
            <div class="field-control">
                <input type="text"
                       v-model="localAnswer.text" />
            </div>
        </div>

        <div class="field-wrapper field-wrapper--2">
            <div class="field">
                <label class="label">Kleurcode 1</label>
                <color-picker-input v-model="localAnswer.color" />
            </div>

            <span class="grey-text grey-text--center">En</span>

            <div class="field">
                <label class="label">Kleurcode 2</label>
                <color-picker-input v-model="localAnswer.secondary_color" />
            </div>
        </div>

        <div class="field field--upload field--row">
            <div>
                <label class="label image-label">
                    Afbeelding
                    <img class="trash"
                         v-if="localAnswer.image != null"
                         @click="uploadStateChanged(null)"
                         src="/img/trash.svg" />
                </label>
                <div v-if="! localAnswer.image">
                    <div class="field">
                        <label :for="'image-' + uuid"
                               class="btn btn--blue">
                            Voeg een afbeelding toe
                        </label>

                        <input :id="'image-' + uuid"
                               @change="uploadStateChanged"
                               accept="image/*"
                               type="file" />
                    </div>
                </div>

                <img :src="answerImage"
                     v-else />
            </div>
            <div>
                <label class="label image-label">
                    Preview
                    <img class="trash"
                         v-if="localAnswer.preview_image != null"
                         @click="uploadStateChanged(null, true)"
                         src="/img/trash.svg" />
                </label>
                <div v-if="!localAnswer.preview_image">
                    <div class="field">
                        <label :for="'image-preview-' + uuid"
                               class="btn btn--blue">
                            Voeg een nieuwe preview toe
                        </label>

                        <input :id="'image-preview-' + uuid"
                               @change="uploadStateChanged($event, true)"
                               accept="image/*"
                               type="file" />
                    </div>
                </div>

                <img :src="answerPreviewImage"
                     v-else />
            </div>
        </div>

        <div class="field">
            <label class="label">{{ $t('articles.article') }}</label>
            <ArticlesInput :max-selection="isCompound ? Infinity : 1"
                           v-model="localAnswer.articles" />
        </div>

        <div class="field">
            <label class="label">{{ $t('products.product') }}</label>
            <ProductInput :max-selection="1"
                          v-model="localAnswer.product" />
        </div>

        <div class="field"
             v-if="! isCompound">
            <label class="label">{{ $t('questions.number') }}</label>
            <div class="field-control">
                <input type="text"
                       v-model="localAnswer.amount" />
            </div>
        </div>

        <div @click="selectDefault"
             class="field field--checkbox">
            <input :checked="!!isDefault"
                   type="checkbox" />
            <div class="field-control">
                <label>{{ $t('questions.is_default_answer') }}</label>
            </div>
        </div>

        <div @click="selectCustomColor"
             class="field field--checkbox"
             :class="{'field--no-margin':!answer.has_custom_color}"
             v-if="! isCompound">
            <input :checked="localAnswer.has_custom_color"
                   type="checkbox" />
            <div class="field-control">
                <label>{{ $t('questions.has_custom_color') }}</label>
            </div>
        </div>

        <div class="field"
             v-if="answer.has_custom_color">
            <label class="label">{{ $t('questions.custom_color_label_1') }}</label>
            <div class="field-control">
                <input type="text"
                       v-model="localAnswer.custom_color_label_1" />
            </div>
        </div>

        <div class="field"
             v-if="answer.has_custom_color">
            <label class="label">{{ $t('questions.custom_color_label_2') }}</label>
            <div class="field-control">
                <input type="text"
                       v-model="localAnswer.custom_color_label_2"
                       :disabled="! localAnswer.custom_color_label_1 || localAnswer.custom_color_label_1.length === 0" />
            </div>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import ColorPickerInput from "../../../components/color-picker-input";
    import OptionsMenu from '../../../components/options-menu';
    import { uuid } from '../../../helpers';
    import ArticlesInput from './ArticlesInput';
    import ProductInput from "./ProductInput";

    export default {
        name: 'answer',

        components: {
            ArticlesInput,
            ProductInput,
            OptionsMenu,
            ColorPickerInput,
        },

        props: {
            title: {
                type: String,
                required: true,
            },

            answer: {
                type: Object,
                required: true,
            },

            isDefault: {
                type: Boolean,
                required: false,
            },

            isLastAnswer: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                localAnswer: this.answer,
                uuid: uuid(),
            };
        },

        computed: {
            isInvalid() {
                if (!this.localAnswer.text) {
                    return true;
                }

                if (!this.isCompound && this.localAnswer.amount < 1) {
                    return true;
                }

                return !this.localAnswer.articles.length;
            },

            isCompound() {
                return this.$store.getters['questions/is_compound'];
            },

            answerImage() {
                if (this.localAnswer.image.indexOf('data:image') === 0) {
                    return this.localAnswer.image;
                }

                return '/' + this.localAnswer.image;
            },

            answerPreviewImage() {
                if (this.localAnswer.preview_image.indexOf('data:image') === 0) {
                    return this.localAnswer.preview_image;
                }

                return '/' + this.localAnswer.preview_image;
            },
        },

        watch: {
            localAnswer: {
                deep: true,
                handler: function (newValue) {
                    this.commit();
                }
            },

            'localAnswer.custom_color_label_1': function (newVal) {
                if (newVal.length === 0) {
                    this.localAnswer.custom_color_label_2 = '';
                }
            },

            'localAnswer.has_custom_color': function (val) {
                if (!val) {
                    this.localAnswer.custom_color_label_1 = '';
                    this.localAnswer.custom_color_label_2 = '';
                }
            },
        },

        methods: {
            moveOrderUp() {
                this.localAnswer.order--;
                this.$emit('orderChanged', {order: this.localAnswer.order, direction: 'up'});
            },

            moveOrderDown() {
                this.localAnswer.order++;
                this.$emit('orderChanged', {order: this.localAnswer.order, direction: 'down'});
            },

            selectCustomColor() {
                this.localAnswer.has_custom_color = !this.localAnswer.has_custom_color;
            },

            deleteAnswer() {
                this.$store.commit('questions/delete_answer', this.localAnswer);
            },

            selectDefault() {
                this.$emit('defaultSelected', this.answer.id);
            },

            uploadStateChanged(e, isPreview = false) {
                if (e === null && window.confirm('Wil je deze foto echt verwijderen?')) {
                    if (isPreview) {
                        this.createPreview(null);
                    } else {
                        this.createImage(null);
                    }
                }

                const files = e.target.files || e.dataTransfer.files;

                if (!files.length) {
                    return;
                }

                if (isPreview) {
                    this.createPreview(files[0]);
                } else {
                    this.createImage(files[0]);
                }
            },

            createPreview(file) {
                if (file === null) {
                    this.localAnswer.preview_image = null;
                    return;
                }

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.localAnswer.preview_image = e.target.result;
                };

                reader.readAsDataURL(file);
            },

            createImage(file) {
                if (file === null) {
                    this.localAnswer.image = null;
                    return;
                }

                const reader = new FileReader();

                reader.onload = (e) => {
                    this.localAnswer.image = e.target.result;
                };

                reader.readAsDataURL(file);
            },

            commit: debounce(function () {
                this.$store.commit('questions/update_answer', this.localAnswer);
            }, 750),
        },
    }
</script>

<style lang="scss"
       scoped>
    .image-label {
        min-width   : 100px;
        display     : flex;
        align-items : center;

        .trash {
            width       : 16px;
            height      : 16px;
            margin-left : 8px;
        }
    }
</style>
