import Vue from 'vue';
import VueI18n from 'vue-i18n';
import general from './general';
import news from './news';
import products from './products';
import articles from './articles';
import questions from './questions';
import specifications from './specifications';
import prosAndCons from './pros-and-cons';
import relevantProducts from './relevant-products';
import accessories from './accessories';
import filters from './filters';
import categories from './categories';
import discountCodes from './discount-codes';
import choiceHelp from './choice-help';
import redirects from './redirects';
import staticPage from './static-page';
import faq from "./faq";
import workers from './workers';
import pages from './pages';

Vue.use(VueI18n);

const translations = {
    en: {
        ...general.en,
        ...products.en,
        ...questions.en,
        ...specifications.en,
        ...articles.en,
        ...prosAndCons.en,
        ...relevantProducts.en,
        ...accessories.en,
        ...filters.en,
        ...categories.en,
        ...news.en,
    },
    nl: {
        ...general.nl,
        ...products.nl,
        ...questions.nl,
        ...specifications.nl,
        ...articles.nl,
        ...prosAndCons.nl,
        ...relevantProducts.nl,
        ...accessories.nl,
        ...filters.nl,
        ...categories.nl,
        ...discountCodes.nl,
        ...news.nl,
        ...choiceHelp.nl,
        ...redirects.nl,
        ...staticPage.nl,
        ...faq.nl,
        ...workers.nl,
        ...pages.nl,
    }
};

const i18n = new VueI18n({
    locale: 'nl',
    fallbackLocale: 'en',
    messages: translations,
});

export default i18n;
