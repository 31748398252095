export default {
    nl: {
        questions: {
            new_question_title: 'Nieuwe vraag toevoegen',
            save: 'Vraag opslaan',
            delete: 'Vraag verwijderen',
            default_question: 'Standaard vraag',
            compound_question: 'Samengestelde vraag',
            question: 'Vraag',
            answer: 'Antwoorden',
            number: 'Aantal',
            order: 'Volgorde',
            is_default_answer: 'Standaard antwoord',
            has_custom_color: 'Eigen kleurstelling',
            custom_color_label_1: 'Vraag 1',
            custom_color_label_2: 'Vraag 2',
            add_question: 'Vraag toevoegen',
            add_answer: 'Antwoord toevoegen',
            multiple_answers_possible: 'Meerdere antwoorden mogelijk',
            parent_question: 'Bovenliggende vraag',
            dependent_question: 'Afhankelijke vraag',
            dependent_answer: 'Afhankelijk antwoord',

            save_success: 'Vraag opgeslagen',
            update_success: 'Vraag aangepast',

            delete_modal: {
                title: 'Vraag verwijderen',
                content: 'Wil je deze vraag echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
            },
        },
    },
};
