<template>
    <div class="panel" :class="{'invalid': isInvalid}">
        <div class="panel-heading">
            <h3 class="panel-title">{{ $t('questions.question') }}</h3>

            <options-menu>
                <a href="#" @click.prevent="deleteQuestion">Verwijderen</a>
            </options-menu>
        </div>

        <div class="field">
            <div class="field-control">
                <input type="text" v-model="localQuestion.text" />
            </div>
        </div>

        <div class="field" :class="{'field--push-hard': ! isCompound}">
            <label class="label">Uitleg</label>
            <div class="field-control">
                <input type="text" v-model="localQuestion.explanation" />
            </div>
        </div>

        <div v-if="availableQuestions.length" class="field"
             :class="{'field--push-hard': !localQuestion.dependent_question_id}">
            <label class="label">{{ $t('questions.dependent_question') }}</label>
            <div class="field-control">
                <select v-model="localQuestion.dependent_question_id">
                    <option value=""></option>
                    <option v-for="question in availableQuestions" :key="'available_question_' + question.id"
                            :value="question.id">
                        {{ question.text }}
                    </option>
                </select>
            </div>
        </div>

        <div class="field field--push-hard" v-if="localQuestion.dependent_question_id">
            <label class="label">{{ $t('questions.dependent_answer') }}</label>
            <div class="field-control">
                <select v-model="localQuestion.dependent_answer_id">
                    <option value=""></option>
                    <option v-for="answer in availableAnswers" :key="'available_answer_' + answer.id"
                            :value="answer.id">
                        {{ answer.text }}
                    </option>
                </select>
            </div>
        </div>

        <h3 class="panel-title panel-title--push panel-title--brighter">{{ $t('questions.answer') }}</h3>

        <div>
            <transition-group name="flip-list">
                <Answer v-for="(answer, index) in orderedAnswers"
                        :title="$t('questions.answer') + ' #' + (index + 1)"
                        :key="'answer_' + answer.id"
                        :answer="answer"
                        v-on:defaultSelected="onDefaultSelected"
                        @orderChanged="orderChanged(answer, $event)"
                        :isDefault="answer.id === localQuestion.default_answer_id"
                        :is-last-answer="index + 1 === orderedAnswers.length" />
            </transition-group>

            <div class="new-row" @click="addNewAnswer">
                <div class="new-row__circle">
                    <img src="/svgs/plus.svg">
                </div>

                <span>{{ $t('questions.add_answer') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Answer from './Answer';
    import { debounce } from 'lodash';
    import OptionsMenu from "../../../components/options-menu";

    export default {
        name: 'question',

        components: {
            OptionsMenu,
            Answer
        },

        props: {
            question: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                localQuestion: this.question,
            };
        },

        computed: {
            orderedAnswers() {
                if(this.localQuestion.answers.filter(answer => answer.order === 0).length > 0)
                {
                    // this list is not ordered yet
                    let count = 1;
                    this.localQuestion.answers.forEach(answer => {
                        answer.order = count;
                        count++;
                    })
                }

                return this.localQuestion.answers.sort((a, b) => (a.order > b.order) ? 1 : -1);
            },
            isInvalid() {
                const isQuestionGroupRequired = this.$store.state.questions.questionGroup.is_required;

                if (!this.localQuestion.answers.length) {
                    return true;
                }

                if (!this.localQuestion.default_answer_id && isQuestionGroupRequired) {
                    return true;
                }

                if (this.localQuestion.dependent_question_id && !this.localQuestion.dependent_answer_id) {
                    return true;
                }

                return !this.localQuestion.text;
            },

            availableQuestions() {
                const questions = this.$store.getters['questions/questions'];

                return questions.filter(q => q.id !== this.localQuestion.id);
            },

            availableAnswers() {
                return this.availableQuestions.find(q => q.id.toString() === this.localQuestion.dependent_question_id.toString()).answers;
            },

            isCompound() {
                return this.$store.getters['questions/is_compound'];
            },
        },

        watch: {
            'localQuestion.text': function () {
                this.commit();
            },

            'localQuestion.explanation': function () {
                this.commit();
            },

            'localQuestion.default_answer_id': function () {
                this.commit();
            },

            'localQuestion.dependent_question_id': function () {
                this.localQuestion.dependent_answer_id = null;
                this.commit();
            },
        },

        methods: {
            orderChanged(movedAnswer, event) {
                const order = event.order;
                const direction = event.direction;

                let answer = this.orderedAnswers.find((answer) => {
                    return answer.order === order && movedAnswer !== answer
                })

                if (direction === 'down') {
                    answer.order = order - 1;
                }

                if (direction === 'up') {
                    answer.order = order + 1;
                }
            },

            addNewAnswer() {
                this.$store.commit('questions/add_new_answer', this.question.id);
            },

            deleteQuestion() {
                this.$store.commit('questions/delete_question', this.localQuestion);
            },

            onDefaultSelected(answerId) {
                const isQuestionGroupRequired = this.$store.state.questions.questionGroup.is_required;

                if (this.localQuestion.default_answer_id === answerId && !isQuestionGroupRequired) {
                    this.localQuestion.default_answer_id = null;

                    return;
                }

                this.localQuestion.default_answer_id = answerId;
            },

            commit: debounce(function () {
                this.$store.commit('questions/update_question', this.localQuestion);
            }, 750),
        },
    }
</script>
