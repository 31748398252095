<template>
    <div>
        <div class="page-header">
            <h1 class="page-title">{{ $t("workers.title") }}</h1>

            <div>
                <a
                    href="#"
                    class="btn btn--primary"
                    @click.prevent="$router.push({ name: 'workers.create' })"
                >
                    {{ $t("workers.add") }}
                </a>
            </div>
        </div>

        <div class="filters">
            <div class="search">
                <img src="/svgs/search-icon.svg" alt="search-icon" />
                <input
                    class="search"
                    type="text"
                    :placeholder="$t('general.search') + '...'"
                    v-model="search"
                />
            </div>
        </div>

        <table class="table" ref="overviewTable">
            <thead>
                <tr>
                    <th>{{ $t("workers.first_name") }}</th>
                    <th>{{ $t("workers.last_name") }}</th>
                    <th>{{ $t("workers.function") }}</th>
                </tr>
            </thead>

            <tbody>
                <router-link
                    :to="{ name: 'workers.edit', params: { id: item.id } }"
                    tag="tr"
                    v-for="item in filteredItems"
                    :key="item.id"
                >
                    <td>{{ item.first_name }}</td>
                    <td>{{ item.last_name }}</td>
                    <td>{{ item.function }}</td>
                </router-link>
            </tbody>
        </table>
    </div>
</template>

<script>
    import WorkerService from "../../services/workers";

    export default {
        name: "workers",
        data() {
            return {
                items: [],
                search: "",
            };
        },
        computed: {
            filteredItems() {
                return this.items.filter((item) => {
                    return this.search
                        .toLowerCase()
                        .split(" ")
                        .every((search) =>
                            ["first_name", "last_name", "function"].some((field) =>
                                item[field].toLowerCase().includes(search)
                            )
                        );
                });
            },
        },
        async mounted() {
            this.items = (await WorkerService.index()).data.data;
        },
    };
</script>
