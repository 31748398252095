import { ProductService } from '../../services';
import { cloneDeep } from 'lodash'

const state = {
    product: {
        tariffPieces: {
            tariff_1: null,
            tariff_2: null,
            tariff_3: null,
            tariff_4: null,
            tariff_5: null,
            tariff_6: null,
            tariff_7: null,
            tariff_8: null,
            tariff_9: null,
        },
    },
};

const getters = {};

const actions = {
    fetch_product({ commit }, productId) {
        const promise = ProductService.show(productId);

        promise.then(response => {
            commit('set_product', response.data);
        });

        return promise;
    },

    update_product({ state }) {
        let product = cloneDeep(state.product);
        product.categories = product.categories.map(c => c.id);
        return ProductService.update(state.product.id, product);
    },
};

const mutations = {
    set_product(state, product) {
        state.product = product;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
