<template>
    <div class="images-container">
        <label for="image-input" class="upload">
            <div>
                <div>Upload hier je foto's</div>
                <label for="image-input" class="btn btn--blue">
                    {{ $t('images.add_image') }}
                </label>

                <input id="image-input"
                       name="images"
                       type="file"
                       @change="(e) => { fileInputChange(e) }">
            </div>
        </label>
        <label class="upload">
            <div>
                <div>{{ $t('news.youtube') }}</div>
                <div class="field video">
                    <div class="field-control">
                        <input type="text" class="display" v-model="youtube" />
                    </div>
                </div>

                <label @click="addVideo(youtube)" class="btn btn--blue">
                    Opslaan
                </label>

                <div @click="deleteVideo()">
                    Video verwijderen
                </div>

            </div>
        </label>

        <div class="images">
            <draggable v-model="images"
                       handle=".drag-handle"
                       @end="onEnd">
                <div v-for="data in images" class="image">
                    <div class="drag-handle">
                        <img src="/svgs/drag.svg" alt="drag-icon">
                    </div>
                    <div @click="deleteImage(data)" class="delete-image">
                        <img src="/svgs/close-icon.svg" alt="close-icon">
                    </div>
                    <div class="field">
                        <img :src="data.path" />
                    </div>
                    <div class="field field-control">
                        <input @change="saveImage(data)" placeholder="Vul hier een alt in" class="show-input"
                               type="text" v-model="data.alt">
                    </div>
                    <div class="field field--checkbox marge" @click="data.hide_margin = !data.hide_margin;saveImage(data)">
                        <input :name="`marge_${data.id}`" type="checkbox" value="1" v-model="data.hide_margin"/>
                        <div class="field-control">
                            <label :v-for="`marge_${data.id}`">Zonder marge tonen</label>
                        </div>
                    </div>
                </div>
            </draggable>
        </div>
    </div>
</template>

<script>
    import { ProductService } from '../../../services';
    import draggable from 'vuedraggable';
    import axios from 'axios';

    export default {
        data() {
            return {
                images: [],
                youtube: "",
            };
        },

        components: { draggable },

        methods: {
            loadImages() {
                ProductService.loadImages(this.$route.params.id)
                    .then(response => {
                        this.images = response.data;
                    });
            },
            loadVideos() {
                ProductService.loadVideos(this.$route.params.id)
                    .then(response => {
                        if (response.data)
                            this.youtube = response.data.youtube_id;
                    });
            },

            addVideo(code) {
                var img = new Image();
                img.src = "http://img.youtube.com/vi/" + code + "/mqdefault.jpg";
                let self = this;

                img.onload = function () {
                    if (img.width === 120) {
                        this.$flashMessage(this.$t('Ongeldige video'), 3500, 'error');
                    } else {
                        ProductService.storeProductVideo(this.$route.params.id, code).then(response => {
                            this.$flashMessage(this.$t('Video opgeslagen'), 3500, 'success');
                        })
                    }
                }.bind(this);
            },

            deleteImage(data) {
                ProductService.deleteProductImage(data.id)
                    .then(response => {
                        this.$flashMessage(this.$t('images.delete_success'), 3500, 'success');
                        this.loadImages()
                    })
                    .catch(err => {
                        this.$flashMessage(this.$t('images.delete_fail'), 3500, 'success');
                    });
            },

            deleteVideo() {
                this.youtube = "";
                ProductService.deleteProductVideo(this.$route.params.id).then(response => {
                    if (response.data) {
                        this.$flashMessage(this.$t('Video verwijderd'), 3500, 'success');
                    } else {
                        this.$flashMessage(this.$t('Video verwijderen mislukt'), 3500, 'error');
                    }
                });
            },

            fileInputChange(e) {
                const files = e.target.files || e.dataTransfer.files;

                if (!files.length) {
                    return;
                }

                this.createImage(files[0]);
            },

            saveImage(data)
            {
                this.$nextTick(() => {
                    ProductService.updateProductImage(data.id, { alt: data.alt, hide_margin: data.hide_margin }).then(response => {
                        if (response.status === 204) {
                            this.$flashMessage(this.$t('Product afbeelding opgeslagen'), 3500, 'success');
                        } else {
                            this.$flashMessage(this.$t('Product afbeelding opslaan mislukt'), 3500, 'error');
                        }
                    });
                });
            },

            createImage(file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.storeImage(e.target.result)
                };
                reader.readAsDataURL(file);
            },

            storeImage(base64) {
                ProductService.storeProductImage(this.$route.params.id, base64)
                    .then(response => {
                        this.images.push({
                            'id': response.data.id,
                            'path': response.data.path,
                        });
                    })
                    .catch(err => {
                        this.$flashMessage(this.$t('images.store_fail'), 3500, 'error')
                    });
            },

            onEnd(e) {
                ProductService.updateProductImagePosition(this.images[e.newIndex].id, e.newIndex + 1);
            },
        },

        mounted() {
            this.loadImages();
            this.loadVideos();
        }
    };
</script>

<style scoped>
    .video {
        margin-top : 45px;
    }

    .field .field-control .display {
        display : block;
    }
</style>
