import BaseService from './index';

export default {
    index(params) {
        return BaseService.get(`/workers`, params);
    },

    store(params) {
        return BaseService.post(`/workers`, params);
    },

    show(itemId) {
        return BaseService.get(`/workers/${itemId}`);
    },

    update(itemId, params) {
        return BaseService.patch(`/workers/${itemId}`, params);
    },

    destroy(itemId) {
        return BaseService.delete(`/workers/${itemId}`);
    },
}
