export default {
    nl: {
        workers: {
            title: 'Werkers',
            edit_title: 'Werker',
            first_name: 'Voornaam',
            last_name: 'Achternaam',
            function: 'Functie',
            image: 'Afbeelding',
            add: 'Werker toevoegen',
            delete_modal_title: 'Werker verwijderen',
            delete_modal_content: 'Wil je deze werker echt verwijderen? Dit kan niet ongedaan worden gemaakt.',
        },
    },
}
